<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          外部报表
        </div>
        <div class="card-hd-right">
        </div>
      </div>
    </div>
    <div class="handleLineBox">
      <div class="baseSelect">
        <!-- 下拉选择 -->
        <SelectDown
          :label="'后勤模板'"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <div class="selected-expand-box">
          <el-popover
            placement="bottom"
            :width="200"
            trigger="hover"
          >
            <slot>
              <div v-if="Object.keys(sortOutData.templateDefind).length >= 1">
                <div>预估快递费 {{ sortOutData.templateDefind.globalLaborFee }} 元/单</div>
                <div>预估人工费 {{ sortOutData.templateDefind.globalExpressdeliveryFee }} 元/单</div>
                <div>有效订单不包括：
                  <span v-for="(item, index) in sortOutData.templateDefind.trueOrderSelectArr" :key="index">
                    {{ item }};
                  </span>
                </div>
              </div>
              后勤模板用以对统计预先设定对应利润和折损的计算方式
            </slot>
            <template #reference>
              <el-icon color="#606266" size="18px" class="card-bd-info-icon"><InfoFilled /></el-icon>
            </template>
          </el-popover>
        </div>
        <el-button type="primary" icon="CirclePlus" @click="pushRouter('addTemplate')">新增后勤模板</el-button>
        <!-- 弹窗 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="SimpleDialogClose"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增后勤模板
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabs.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabs.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        status-icon
                        :rules="rules"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="后勤模板名称" prop="templateName">
                                <el-input v-model="ruleForm.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="请填写后勤模板名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              有效订单设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="有效订单不包括：" prop="trueOrderSelectArr">
                                <el-checkbox-group v-model="ruleForm.trueOrderSelectArr">
                                  <el-checkbox
                                    v-for="item in ruleForm.trueOrderTypeSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                  />
                                </el-checkbox-group>
                              </el-form-item>
                            </div>
                            <div class="card-bd-item is-alone greylight2">
                              （ 有效订单 = 【全部订单】
                              <span v-for="item in ruleForm.trueOrderSelectArr" :key="item">
                                - {{ item }}
                              </span>
                              ）
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              人工/快递支出设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item">
                              <el-form-item label="预估快递费/单" prop="globalExpressdeliveryFee">
                                <el-input v-model="ruleForm.globalExpressdeliveryFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalExpressdeliveryFee')"
                                  @change="changeNumberCheck($event, 'globalExpressdeliveryFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估快递费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                            <div class="card-bd-item">
                              <el-form-item label="预估人工费/单" prop="globalLaborFee">
                                <el-input v-model="ruleForm.globalLaborFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalLaborFee')"
                                  @change="changeNumberCheck($event, 'globalLaborFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估人工费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <SelectDown
          :label="'SKU成本'"
          :selectVal="baseData.selectValSkuCost"
          :selectData="baseData.selectDataSkuCost"
          @selectDownCall="selectDownSkuCostHandle"
        ></SelectDown>
        <div class="selected-expand-box">
          <el-popover
            placement="bottom"
            :width="200"
            trigger="hover"
            content="成本表用以对统计预先设定SKU成本，用以统计利润等等"
          >
            <template #reference>
              <el-icon color="#606266" size="18px" class="card-bd-info-icon"><InfoFilled /></el-icon>
            </template>
          </el-popover>
        </div>
        <el-button type="primary" icon="CirclePlus" @click="pushRouter('addSkucost')">新增SKU成本表</el-button>
        <!-- 弹窗 Sku层 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SkuDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="baseData.SkuDialogShow = false"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增SKU成本表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsSku.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabsSku.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormSkuCostRef"
                        :model="ruleFormSkuCost"
                        status-icon
                        :rules="rulesSkuCost"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="SKU成本表名称" prop="templateName">
                                <el-input v-model="ruleFormSkuCost.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="SKU成本表名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                            <el-button
                              type="primary"
                              icon="Upload"
                              @click="comHandleHandleUpload('sku')"
                            >上传SKU成本表</el-button>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table :data="ruleFormSkuCost.list" stripe style="width: 940px">
                                <!-- <el-table-column prop="id" label="skuId" width="120" /> -->
                                <el-table-column prop="SKU名称" label="SKU名称"/>
                                <el-table-column prop="SKU价格" label="SKU价格" width="120" />
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitFormSkuCost(ruleFormSkuCostRef)">保存</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <el-button type="primary" icon="Upload" @click="comHandleHandleUpload()" v-if="exlcelData?.header.length !== 0">上传外部报表</el-button>
      </div>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

    <div class="echart-demo">
      <div id="myChart3" class="echart-box" v-if="false"></div>
      <div id="myChartLast" class="echart-box"></div>
    </div>

    <el-scrollbar v-if="true" height="500px">
      <!-- 商品ID维度 -->
      <div v-if="false">
        <div>数据分析：</div>
        <br>
        <div>共有:{{ exlcelData.results.length ? exlcelData.results.length : 0 }} 张订单</div>
        <br>
        <div>
          · 订单共有 {{ sortOutData.orderArr.length }} 种状态，其中：
          <div v-for="(item, index) in sortOutData.orderArr" :key="index">
            "{{ item.type  }}" 共 {{ item.list.length }} 单, 占订单比 {{ item.ratio ? (item.ratio) * 100 : 0 }} %
          </div>
        </div>
        <br>
        <div>
          · 共销售: {{ sortOutData.idArr.length ? sortOutData.idArr.length : 0 }} 款商品，其中
          <div v-for="(item, index) in sortOutData.baseArr" :key="index">
            {{ item['商品'] }}
            <br>
            共销售 {{ item.sum }} 单，其中：
            <div v-for="(itemType, indexType) in sortOutData.orderTypeArr" :key="indexType">
              {{ itemType }} 有 {{ item[`sum${itemType}`] ? item[`sum${itemType}`] : 0 }} 单，占该商品销量比 {{ item[`sum${itemType}`] ? ((item[`sum${itemType}`]/item.sum) * 100).toFixed(2) : 0 }} %
            </div>
          </div>
        </div>
        <br>
        <div>
          · 共有SKU: {{ sortOutData.skuArr.length ? sortOutData.skuIdArr.length : 0 }} 个，其中
          <div v-for="(item, index) in sortOutData.skuArr" :key="index">
            {{ item['商品'] }} - {{ item['商品规格'] }}
            <br>
            共销售 {{ item.sum }} 单，其中：
            <div v-for="(itemType, indexType) in sortOutData.orderTypeArr" :key="indexType">
              {{ itemType }} 有 {{ item[`sum${itemType}`] ? item[`sum${itemType}`] : 0 }} 单，占该sku销量比 {{ item[`sum${itemType}`] ? ((item[`sum${itemType}`]/item.sum) * 100).toFixed(2) : 0 }} %
            </div>
          </div>
        </div>
        <br>
      </div>
      <!-- 收货时间维度 table 预处理 -->
      <el-table v-if="false" :data="sortOutData.takeOverDetailArr" style="width: calc(100% - 20px)">
        <el-table-column label="日期">
          <template #default="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>
        <el-table-column label="销售额(元)">
          <template #default="scope">
            {{ scope.row.moneySum.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="客单价(元)">
          <template #default="scope">
            {{ (scope.row.moneySum.toFixed(2) / scope.row.list.length).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="物流状态">
          <template #default="{ cloumn, row  }">
              <el-table-column :label="cloumn"  :data-test="row" :data-test1="cloumn">
                <template #default="{ cloumn, row  }">
                  <div :data-cloumn="cloumn" :data-row="row">{{ cloumn }} {{ row }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column :label="cloumn"  :data-test="row" :data-test1="cloumn"></el-table-column> -->
              <!-- <el-table-column :label="row.status" width="100"
                v-for="(item, index) in row" :key="index"
              >
              </el-table-column> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 收货时间维度 -->
      <div v-if="true">
        <div v-for="(item, index) in sortOutData.takeOverDetailArr" :key="index">
          {{ item.time }} 共产生了 {{ item.list.length }} 个订单
          销售额为{{ item.moneySum.toFixed(2) }}元,
          客单价为{{ (item.moneySum.toFixed(2) / item.list.length).toFixed(2) }}元,
          <div>
            其中
            <span v-for="(statusItems, statusIndexs) in item.status" :key="statusIndexs">
            【 {{ statusItems.type }}】 {{ statusItems.list.length }} 单
              ({{ statusItems.moneySum.toFixed(2) }}元)
              。
            </span>
          </div>
          <div v-if="Object.keys(sortOutData.templateDefind).length !== 0">
            根据模板设定，
            {{ item.time }} 有 {{ item.trueOrders.orderNum }} 个有效订单，
            销售额为 {{ (item.trueOrders.moneySum).toFixed(2)  }} 元，
            <span v-if="Object.keys(sortOutData.templateDefindSkuCost).length !== 0">
              商品成本为 {{ (item.trueOrders.skuCostSum).toFixed(2) }} 元，
              销售毛利为 {{ (item.trueOrders.moneySum - item.trueOrders.skuCostSum).toFixed(2) }} 元，
              毛利率为 {{ (((item.trueOrders.moneySum - item.trueOrders.skuCostSum)/item.trueOrders.moneySum) * 100).toFixed(2) }} %。
            </span>
            人工耗费 {{ (item.feeLabor).toFixed(2) }} 元，
            物流耗费 {{ (item.feeExpressdelivery).toFixed(2) }} 元。
          </div>
          <br>
        </div>
      </div>
    </el-scrollbar>

  </div>
</template>

<script setup>
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import { onMounted, reactive, ref, watch, nextTick } from 'vue'
import * as echarts from 'echarts'
import { ordernumToTime } from '@/utils/unitTransform.js'
import SelectDown from '@/components/SelectDown/index.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { logisticsTemplateList, skuCostList, logisticsTemplateDetail, skuCostDetail } from '@/api/report.js'
import { useRouter } from 'vue-router'

const router = useRouter()
const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const sortOutData = reactive({
  templateDefind: {}, // 已选模板对应的模板字段详情
  baseArr: [],
  idArr: [],
  skuArr: [],
  skuIdArr: [],
  orderArr: [],
  orderTypeArr: [],
  takeOverDetailArr: [],
  takeOverTimeArr: [],
  templateDefindSkuCost: {} // 已选SKU成本表模板对应的模板字段详情
})
const baseData = reactive({
  colorArr: ['#6F61E6', '#e66161', '#d161e6', '#6188e6', '#61dce6', '#6f61e6', '#369', '#fe8720', '#999', '#fe0000'],
  selectVal: '',
  selectData: [],
  SimpleDialogShow: false,
  selectValSkuCost: '',
  selectDataSkuCost: [],
  SkuDialogShow: false
})

// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
const tabs = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '有效订单设置', name: 1 },
    { title: '人工/快递支出', name: 2 }
  ]
})
// tabs sku信息
const tabsSku = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: 'sku成本表预览', name: 1 }
  ]
})
// elementPlus滚动条缓动
const scrollToTopTransition = (willGo, nowSite = 0, duration = 300) => {
  let passedTime = 0
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.value.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}
// tabs点击事件
const tabsListHandle = (e) => {
  const indexNum = Number(e.index)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabs.list[indexNum].offsetTop - 12, baseData.scrollSite)
}
// tabs 监听滚动
const doScroll = (event) => {
  const list = tabs.list
  baseData.scrollSite = event.scrollTop
  for (const i in list) {
    console.log(tabs.list[i].offsetTop, '--', baseData.scrollSite)
    const { offsetTop, name } = tabs.list[i]
    if (offsetTop >= baseData.scrollSite) {
      tabs.currentIndex = name
      break
    }
  }
}
// 获取cradItem锚点距顶坐标
const getCardItemSite = () => {
  nextTick(() => {
    const list = tabs.list
    for (const i in list) {
      tabs.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}
// tabs currentIndex监听
watch(() => tabs.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite()
})

// 后勤模板表单 action
// 表单 信息
const ruleForm = reactive({
  brand: '',
  orderStatus: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，待收货', '已发货，退款成功', '已收货', '已收货，退款成功'],
  templateName: '', // 模板名称
  trueOrderSelectArr: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，退款成功', '已收货，退款成功'], // 有效订单=减去对应类型(减法)
  trueOrderTypeSelect: [
    {
      value: '1',
      label: '待付款'
    },
    {
      value: '2',
      label: '已取消'
    },
    {
      value: '3',
      label: '待发货'
    },
    {
      value: '4',
      label: '未发货，退款成功'
    },
    {
      value: '5',
      label: '已发货，待收货'
    },
    {
      value: '6',
      label: '已发货，退款成功'
    },
    {
      value: '7',
      label: '已收货'
    },
    {
      value: '8',
      label: '已收货，退款成功'
    }
  ],
  globalLaborFee: '', // 全局人工费
  globalExpressdeliveryFee: '' // 全局快递费
})
// 表单 验证相关
const ruleFormRef = ref()
// 表单 单独校验规则
const checkBrand = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入品牌名称'))
  }
  setTimeout(() => {
    if (value.length > 30) {
      callback(new Error('品牌名不能超过30个字'))
    } else {
      callback()
    }
  }, 1000)
}
// 表单 模板名校验规则
const checkTemplateName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 费用校验规则
const checkFee = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请设置费用'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rules = reactive({
  brand: [{ required: true, validator: checkBrand, trigger: 'blur' }],
  templateName: [{ required: true, validator: checkTemplateName, trigger: 'blur' }],
  globalLaborFee: [{ required: true, validator: checkFee, trigger: 'blur' }],
  globalExpressdeliveryFee: [{ required: true, validator: checkFee, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleForm)
      editLogisticsListHandle()
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl) => {
  if (!formEl) return
  ElMessageBox.confirm(
    '重置后所有未保存的内容均会丢失，是否继续？',
    {
      confirmButtonText: '重置',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    formEl.resetFields()
  }).catch(() => {
  })
}
// simpleDialog 关闭事件
const SimpleDialogClose = () => {
  baseData.SimpleDialogShow = false
}
const pushRouter = (type) => {
  console.log('pushRouter', type)
  switch (type) {
    case 'addSkucost':
      router.push({ name: 'reportSkuCost', params: { event: 'addSkucost' } })
      break
    case 'addTemplate':
      router.push({ name: 'reportTemplate', params: { event: 'addTemplate' } })
      break
  }
}
// 下拉选择相关
const selectDownHandle = async (val) => {
  baseData.selectVal = val
  sortOutData.templateDefind = {}
  const resData = await logisticsTemplateDetail({
    template_id: baseData.selectVal
  })
  // 别名重新定义
  const {
    expected_express_fee: globalExpressdeliveryFee,
    expected_labor_cost: globalLaborFee,
    invalid_order_status: trueOrderSelectArr,
    template_id: templateId,
    id
  } = resData
  // 有效订单 — 物流状态映射中文
  const nameChangetrueOrderSelectArr = []
  for (const i in ruleForm.trueOrderTypeSelect) {
    if (JSON.parse(trueOrderSelectArr).indexOf(ruleForm.trueOrderTypeSelect[i].value) !== -1) {
      nameChangetrueOrderSelectArr.push(ruleForm.trueOrderTypeSelect[i].label)
    }
  }
  const nameChange = {
    globalExpressdeliveryFee,
    globalLaborFee,
    trueOrderSelectArr: nameChangetrueOrderSelectArr,
    templateId,
    id
  }
  sortOutData.templateDefind = nameChange
  console.log('sortOutData.templateDefindsortOutData.templateDefind', sortOutData)
}

// 后勤模板表单 end

// Sku成本表表单 action
// 表单 信息
const ruleFormSkuCost = reactive({
  templateName: '', // 模板名称
  list: []
})
// 表单 验证相关
const ruleFormSkuCostRef = ref()
// 表单 模板名校验规则
const checkSkuCostName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rulesSkuCost = reactive({
  templateName: [{ required: true, validator: checkSkuCostName, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitFormSkuCost = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleFormSkuCost)
      editSkuCostListHandle()
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 下拉选择相关
const selectDownSkuCostHandle = async (val) => {
  console.error('下拉选择相关', sortOutData, val)
  baseData.selectValSkuCost = val
  const resData = await skuCostDetail({
    template_id: baseData.selectValSkuCost
  })
  sortOutData.templateDefindSkuCost = resData
}
// Sku成本表表单 end
// 基础组件 事务 end

// 整理事件 - SKU成本表分析
const skuCostComputerHandle = (dayTrueOrderItem, dayOrderItem) => {
  // 遍历指定日期有效订单列表
  for (const i in dayTrueOrderItem.list) {
    const item = dayTrueOrderItem.list[i]
    let skuName = ''
    // 匹配sku名称，已知表头命名目前有两个—— 商家编码-规格维度、商品规格
    if (item['商家编码-规格维度']) {
      skuName = item['商家编码-规格维度']
    } else if (item['商品规格']) {
      skuName = item['商品规格']
    }
    // 遍历sku名称遍历sku成本表，匹配sku成本表，获取sku成本价格
    for (const j in sortOutData.templateDefindSkuCost) {
      const skuCostItem = sortOutData.templateDefindSkuCost[j]
      if (skuName === skuCostItem.sku_name) {
        dayOrderItem.trueOrders.skuCostSum += Number(skuCostItem.cost_price)
        break
      }
    }
  }
}

// 整理事件 - 订单比率层
const sortOrderRatioHandle = () => {
  for (const i in sortOutData.orderArr) {
    const list = sortOutData.orderArr[i].list
    const type = sortOutData.orderArr[i].type
    // 各个发货状态订单数占总订单比
    sortOutData.orderArr[i].ratio = (sortOutData.orderArr[i].list.length / exlcelData.results.length).toFixed(4)
    // 各个发货状态sku销量统计
    for (const j in list) {
      const listItem = list[j]
      const skuIdIndex = sortOutData.skuIdArr.indexOf(listItem['样式ID'])
      const goodIdIndex = sortOutData.idArr.indexOf(listItem['商品id'])
      if (skuIdIndex !== -1) {
        // 该sku对应商品所有订单状态总销量统计
        !sortOutData.baseArr[goodIdIndex]?.sum ? (sortOutData.baseArr[goodIdIndex].sum = 1) : (sortOutData.baseArr[goodIdIndex].sum += 1)
        // 该sku对应商品各个订单状态总销量统计
        sortOutData.baseArr[goodIdIndex][`sum${type}`] ? (sortOutData.baseArr[goodIdIndex][`sum${type}`] += 1) : (sortOutData.baseArr[goodIdIndex][`sum${type}`] = 1)
        // 该sku所有订单状态总销量统计
        !sortOutData.skuArr[skuIdIndex]?.sum ? (sortOutData.skuArr[skuIdIndex].sum = 1) : (sortOutData.skuArr[skuIdIndex].sum += 1)
        // 该sku各个订单状态总销量统计
        sortOutData.skuArr[skuIdIndex][`sum${type}`] ? (sortOutData.skuArr[skuIdIndex][`sum${type}`] += 1) : (sortOutData.skuArr[skuIdIndex][`sum${type}`] = 1)
      }
    }
  }
  // console.log('~~', sortOutData)
}

// 整理事件 - 订单状态层
const sortOrderHandle = (item) => {
  const ordeStatusItem = item['订单状态']
  const itemIndexof = sortOutData.orderTypeArr.indexOf(ordeStatusItem)
  if (itemIndexof === -1) {
    sortOutData.orderTypeArr.push(ordeStatusItem)
    sortOutData.orderArr.push({
      type: ordeStatusItem,
      list: [item]
    })
  } else {
    sortOutData.orderArr[itemIndexof].list.push(item)
  }
}

// 整理事件 - SKU层
const sortSkuHandle = (skuid, item) => {
  const skuItem = item['样式ID']
  if (sortOutData.skuIdArr.indexOf(skuItem) === -1) {
    sortOutData.skuArr.push(item)
    sortOutData.skuIdArr.push(skuid)
  }
}

// 整理事件 - 时间层
const sortOrderTimeHandle = async () => {
  for (const i in sortOutData.takeOverDetailArr) {
    const items = sortOutData.takeOverDetailArr[i]
    const itemsStatus = items.status
    const listItem = items.list
    if (listItem.length > 0) {
      for (const j in listItem) {
        const item = listItem[j]
        const timeItemStatus = item['订单状态']
        for (const z in itemsStatus) {
          if (itemsStatus[z].type === timeItemStatus) {
            itemsStatus[z].moneySum += item['商家实收金额(元)']
            itemsStatus[z].list.push(item)
            // 当天总和
            items.moneySum += item['商家实收金额(元)']
          }
        }
      }
    }
  }
}

// 整理数据 - 集合模板对数据进行二次整合
const templateIntegrationHandle = () => {
  const templateDefind = (Object.keys(sortOutData.templateDefind).length !== 0)
  const templateDefindSkuCost = (Object.keys(sortOutData.templateDefindSkuCost).length !== 0)
  if (!templateDefind) {
    ElMessage.error('暂未使用后勤模板，无法统计有效订单、每单快递费，每单人工费')
    return
  }
  if (!templateDefindSkuCost) {
    ElMessage.error('暂未使用SKU成本表，无法统计商品成本，销售毛利，毛利率')
  }
  const { globalExpressdeliveryFee, globalLaborFee, trueOrderSelectArr } = sortOutData.templateDefind
  for (const i in sortOutData.takeOverDetailArr) {
    const takeOverItem = sortOutData.takeOverDetailArr[i]
    takeOverItem.trueOrders = {
      moneySum: 0,
      orderNum: 0,
      skuCostSum: 0
    }
    for (const j in takeOverItem.status) {
      // 计算人工费和快递费（实际发包产生人工费和快递费），此处为固定算法，看看人工费是否要剥离
      console.error('Comedy，有问题 conditionFeeSet')
      const conditionFeeSet = ['已发货，退款成功', '已收货，退款成功', '已收货']
      const conditionLength = takeOverItem.status[j].list.length
      const conditioMoneySum = takeOverItem.status[j].moneySum
      if (conditionFeeSet.indexOf(takeOverItem.status[j].type) !== -1) {
        // 累加快递费
        if (takeOverItem.feeExpressdelivery) {
          takeOverItem.feeExpressdelivery += (globalExpressdeliveryFee * conditionLength)
        } else {
          takeOverItem.feeExpressdelivery = (globalExpressdeliveryFee * conditionLength)
        }
        // 累加人工费
        if (takeOverItem.feeLabor) {
          takeOverItem.feeLabor += (globalLaborFee * conditionLength)
        } else {
          takeOverItem.feeLabor = (globalLaborFee * conditionLength)
        }
      }
      // 计算真实订单
      if (trueOrderSelectArr.indexOf(takeOverItem.status[j].type) === -1) {
        takeOverItem.trueOrders.moneySum += conditioMoneySum
        takeOverItem.trueOrders.orderNum += conditionLength
        // SKU成本维度
        // 结合SKU成本表，计算商品总成本，销售毛利，毛利率
        templateDefindSkuCost && skuCostComputerHandle(takeOverItem.status[j], takeOverItem)
      }
    }
  }
}

// 整理事件 - 顶层
const sortOutHandle = () => {
  for (const i in exlcelData.results) {
    const item = exlcelData.results[i]
    const idItem = item['商品id']
    const skuIdItem = item['样式ID']
    const takeOverTimeItem = ordernumToTime(item['订单号'])
    // 商品id维度
    if (sortOutData.idArr.indexOf(idItem) === -1) {
      sortOutData.baseArr.push(item)
      sortOutData.idArr.push(idItem)
      sortOutData.skuArr.push(item)
      sortOutData.skuIdArr.push(skuIdItem)
    } else {
      sortSkuHandle(skuIdItem, item)
    }
    sortOrderHandle(item)
    // 订单时间维度 - 1
    const itemIndexof = sortOutData.takeOverTimeArr.indexOf(takeOverTimeItem)
    if (sortOutData.takeOverTimeArr.indexOf(takeOverTimeItem) === -1) {
      console.error('orderNum的问题合理出现在这一块')
      sortOutData.takeOverTimeArr.push(takeOverTimeItem)
      const tempOrderTypeObj = []
      // 初始化订单状态obj start
      const defindOrderTypeArr = ruleForm.orderStatus
      for (const i in defindOrderTypeArr) {
        tempOrderTypeObj.push({
          type: defindOrderTypeArr[i],
          list: [],
          moneySum: 0
        })
      }
      // 初始化订单状态obj end
      sortOutData.takeOverDetailArr.push({
        time: takeOverTimeItem,
        list: [item],
        status: tempOrderTypeObj,
        moneySum: 0
      })
    } else {
      sortOutData.takeOverDetailArr[itemIndexof].list.push(item)
    }
  }
  sortOrderRatioHandle()
  // 订单时间维度 - 2
  sortOrderTimeHandle().then(() => {
    // 集合模板对数据进行二次整合
    templateIntegrationHandle()
    // 初始化柱状商品销量图
    if (templateIntegrationHandle === 10086) createBarTickEchart()
    // 初始化柱状时间销量图
    createTimeBarTickEchart()
  }).catch((error) => {
    ElMessage.error('报表解析出错')
    console.log(error)
    clearExcelData()
  })
  // SKU成本维度
  // 中途执行这个 -> skuCostComputerHandle()
  // console.log(sortOutData.baseArr, sortOutData.idArr)
  // console.log(sortOutData.skuArr, sortOutData.skuIdArr)
  // console.log(sortOutData.orderArr, sortOutData.orderTypeArr)
  console.log('takeOverDetailArr', sortOutData.takeOverDetailArr)
}

// 置空数据
const clearExcelData = (resData) => {
  exlcelData.header = {}
  exlcelData.results = {}
  sortOutData.baseArr = []
  sortOutData.idArr = []
  sortOutData.skuArr = []
  sortOutData.skuIdArr = []
  sortOutData.orderArr = []
  sortOutData.orderTypeArr = []
  sortOutData.takeOverDetailArr = []
  sortOutData.takeOverTimeArr = []
  resData && Object.assign(exlcelData, resData)
  console.log(exlcelData.header)
}

// excel组件回调
const excelCall = (resData) => {
  // scene = '' 默认为基础表格
  if (!resData?.scene) {
    let baseSceneStatus = false
    const baseSceneArr = ['商品id', '样式ID', '订单号', '商品', '商品规格', '订单状态', '商家实收金额(元)', '订单号']
    // 如果是默认数据表上传，则遵循默认表格规范
    for (const i in baseSceneArr) {
      if (resData.header.indexOf(baseSceneArr[i]) !== -1) {
        baseSceneStatus = true
      }
    }
    if (!baseSceneStatus) {
      ElMessage.error(`外部报表包含必须：${baseSceneArr}`)
      return
    }
    ElMessage.success('外部报表上传成功')
    clearExcelData(resData)
    sortOutHandle()
  } else if (resData?.scene === 'sku') {
    // 如果是sku成本表上传，则要遵循sku成本表规范
    if (resData.header.length !== 2) {
      ElMessage.error('sku成本表表头必须为"SKU名称","SKU价格"')
    } else {
      ElMessage.success('sku成本表上传成功"')
      ruleFormSkuCost.list = resData.results
    }
  }
}

// 初始化柱状商品销量图
const createBarTickEchart = () => {
  const headerData = sortOutData.baseArr.map(obj => obj['商品'])
  const itemsType = [
    '总销量', ...ruleForm.orderStatus
  ]
  const itemDataArr = [
    {
      name: '总销量',
      type: 'bar',
      barGap: 0,
      barWidth: `${(1 / (sortOutData.orderTypeArr.length + 1) * 100).toFixed(2)}%`,
      data: sortOutData.baseArr.map(obj => obj.sum),
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        borderRadius: [4, 4, 4, 4],
        color: baseData.colorArr[0]
      }
    }
  ]
  for (const i in sortOutData.orderTypeArr) {
    const itemData = {
      name: sortOutData.orderTypeArr[i],
      type: 'bar',
      barWidth: `${(1 / (sortOutData.orderTypeArr.length + 1) * 100).toFixed(2)}%`,
      data: sortOutData.baseArr.map(obj => obj[`sum${sortOutData.orderTypeArr[i]}`] ? obj[`sum${sortOutData.orderTypeArr[i]}`] : 0),
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        borderRadius: [4, 4, 4, 4],
        color: baseData.colorArr[`${(Number(i) + 1) % 10}`]
      }
      // stack: 'Ad'
    }
    itemDataArr.push(itemData)
  }

  const myChart3 = echarts.init(document.getElementById('myChart3'))
  const barTick = {
    title: {
      // text: '商品销量分析'
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        saveAsImage: { show: true },
        dataView: { show: true, readyOnly: true },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        savesImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'axis',
      width: 120,
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '3%',
      containLabel: true
    },
    axisLabel: {
      fontSize: 12,
      formatter: function(value) {
        if (value.length > 10) {
          return value.substr(0, 10) + '...'
        } else {
          return value
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: headerData,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: itemDataArr,
    legend: {
      data: itemsType
    }
  }
  myChart3.setOption(barTick)
  window.onresize = function () { // 自适应大小
    myChart3.resize()
  }
}
// 初始化柱状时间销量图
const createTimeBarTickEchart = () => {
  const headerData = sortOutData.takeOverDetailArr.reverse().map(obj => obj.time)
  const itemsType = [
    '总销量', ...ruleForm.orderStatus
  ]
  const itemDataArr = []

  let orderAllStatusItem = {}
  orderAllStatusItem = {
    name: '总销量',
    type: 'line',
    data: sortOutData.takeOverDetailArr.map(obj => {
      // return `${obj.list.length}单 | ${(obj.moneySum).toFixed(2)}元`
      return `${obj.list.length}`
    }),
    emphasis: {
      focus: 'series'
    },
    itemStyle: {
      color: baseData.colorArr[0]
    }
  }
  itemDataArr.push(orderAllStatusItem)

  for (const i in ruleForm.orderStatus) {
    itemDataArr.push({
      name: `${ruleForm.orderStatus[i]}`,
      type: 'line',
      data: sortOutData.takeOverDetailArr.map(obj => {
        // return `${obj.status[i].list.length}单 | ${(obj.status[i].moneySum).toFixed(2)}元`
        return `${obj.status[i].list.length}`
      }),
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        color: baseData.colorArr[`${(Number(i) + 1) % 10}`]
      },
      stack: 'Ad'
    })
  }

  const myChartLast = echarts.init(document.getElementById('myChartLast'))
  const barTickLast = {
    title: {
      // text: '销量时间表'
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        saveAsImage: { show: true },
        dataView: { show: true, readyOnly: true },
        magicType: { show: true, type: ['line', 'stack'] },
        restore: { show: true },
        savesImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'axis',
      width: 120,
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      containLabel: true
    },
    axisLabel: {
      fontSize: 12,
      formatter: function(value) {
        if (value.length > 5) {
          return value.substr(value.length - 4, value.length)
        } else {
          return value
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: headerData,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: itemDataArr,
    legend: {
      data: itemsType
    }
  }
  myChartLast.setOption(barTickLast)
  window.onresize = function () { // 自适应大小
    myChartLast.resize()
  }
}

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}

// input @input 键入数字校验
const inputNumberCheck = (e, ruleFormKey) => {
  if (Number(e) > 9999) {
    ruleForm[ruleFormKey] = 9999
  }
  if (Number(e) < 0) {
    ruleForm[ruleFormKey] = 0
  }
}
// input @change 键入数字校验
const changeNumberCheck = (e, ruleFormKey) => {
  ruleForm[ruleFormKey] = Number(e)
}

// 编辑后勤模板
const editLogisticsListHandle = () => {
  const temp = JSON.stringify([{
    templateName: ruleForm.templateName,
    globalLaborFee: ruleForm.globalLaborFee,
    globalExpressdeliveryFee: ruleForm.globalExpressdeliveryFee,
    trueOrderSelectArr: ruleForm.trueOrderSelectArr,
    id: new Date().getTime()
  }])
  localStorage.setItem('tempLogistics', temp)
  console.log('后勤模板:', temp)
  ElMessage.success('后勤模板暂存成功')
  SimpleDialogClose()
  getLogisticsListHandle()
}
// 获取后勤模板列表
const getLogisticsListHandle = async () => {
  baseData.selectData = [
    {
      value: '',
      label: '不使用后勤模板'
    }
  ]
  const resData = await logisticsTemplateList({
    template_name: apiData.templateName
  })
  if (resData?.length > 0) {
    const resList = resData
    for (const i in resList) {
      baseData.selectData.push({
        value: resList[i].id,
        label: resList[i].name
      })
    }
  }
  baseData.selectVal = ''
}

// 编辑sku成本表
const editSkuCostListHandle = () => {
  const temp = JSON.stringify([{
    templateName: ruleFormSkuCost.templateName,
    list: ruleFormSkuCost.list,
    id: new Date().getTime()
  }])
  localStorage.setItem('tempSkuCost', temp)
  console.log('SKU成本表:', temp)
  ElMessage.success('SKU成本表暂存成功')
  baseData.SkuDialogShow = false
  getSkucostListHandle()
}
// 获取sku成本表
const getSkucostListHandle = async () => {
  baseData.selectDataSkuCost = [
    {
      value: '',
      label: '不使用sku成本表'
    }
  ]
  const resData = await skuCostList({
    template_name: apiData.skuCostTemplateName
  })
  if (resData.length > 0) {
    const resList = resData
    for (const i in resList) {
      baseData.selectDataSkuCost.push({
        value: resList[i].id,
        label: resList[i].name
      })
    }
  }
  baseData.selectValSkuCost = ''
}

// api交互相关
// api交互相关
// api交互相关
const apiData = reactive({
  templateName: '',
  skuCostTemplateName: '',
  currentPage: 1,
  pageSize: 10
  // pageTotal: 0,
  // pageSizes: [100, 200, 300, 400],
})
// // 分页相关
// const handleSizeChange = (val) => {
//   console.log(`${val} items per page`)
// }
// const handleCurrentChange = (val) => {
//   console.log(`current page: ${val}`)
// }

onMounted(() => {
  getLogisticsListHandle()
  getSkucostListHandle()
})

</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';

.detailFormDialogBox {
  @include dialogBoxBase;
  .el-dialog {
    height: calc( 100% - 80px);
  }
  .card-box {
    .card-bd {
      padding-top: 24px;
    }
  }
}
.goodsForm {
  @include formBoxBase;
  height: calc( 100% - 100px);
}
.echart-demo {
  display: flex;
  flex-wrap: wrap;
  .echart-box {
    width: 100%;
    height: 300px;
  }
}
.selectDownBox {
  transition: none;
  + .el-button {
    margin-left: 12px;
  }
  + .selected-expand-box {
    margin: 0 12px;
  }
}
.height-auto-table {
  .el-scrollbar {
    height: calc(100vh - 540px);
    padding: 0;
  }
}
</style>
